import React from "react"
import HeaderAbout from "./HeaderAbout/HeaderAbout"
import photo from '../img/aboutphoto.png'
import { NavLink } from "react-router-dom"
import state from '../state'
import s from './StylePages.module.css'
import Back from "./NavComponents/Back"
import Next from "./NavComponents/Next"
import Home from "./NavComponents/Home"

const About = () => {
    return (
        <div className={s.about}>
            <HeaderAbout />
            <img src={photo} alt="me" className={s.about_photo}/>
            <p className={s.about_text}>My name is Yan Gavrilov. I am an experienced and creative designer. I create identifications, 
            logos and packaging for small ambitious and big companies. My contribution to your company will make it unique, memorable and perfect. 
            I hope you like my works and I am looking forward to our cooperation.</p>
            <div className={s.block_links}>
                <NavLink to={{
                    pathname: `/project/${state[state.length-1].folder}`,
                    response: state[state.length-1]
                }}> <Back /> </NavLink>
                <NavLink to='/'> <Home /> </NavLink>
                <NavLink to={{
                    pathname: `/project/${state[0].folder}`,
                    response: state[0]
                }}> <Next /> </NavLink>
            </div>
            <footer className={s.footer_about}>
                <address className={s.address_about} id="address">
                    <span>Developed Aleksander Pavlenko. Designed by Yan Gavrilov</span>
                    <span>designer@yangavrilov.com</span>
                    <span>© Yan Gavrilov</span>
                </address>
            </footer>
        </div>
    )
}

export default About
import React from "react"
import s from './NavComponents.module.css'

const Home = () => {
    return (
        <div className={s.box}>
            <div className={s.box_item}></div>
        </div>
    )
}

export default Home